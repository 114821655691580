import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'http://localhost:5000/api',
        baseUrl: 'http://146.120.248.94:5000/api',
    }),
    tagTypes: ['StorageBattery', 'Generator'], // Додаємо тег для генератора
    endpoints: (builder) => ({
        getElectricityData: builder.query({
            query: () => '/electricity',
        }),
        getElectricityFilterData: builder.query({
            query: ({ startDate, endDate }) => {
                const params = new URLSearchParams();
                if (startDate) params.append('startDate', startDate);
                if (endDate) params.append('endDate', endDate);
                return {
                    url: `/electricity/filter?${params.toString()}`,
                    method: 'GET',
                };
            },
        }),
        getRelayData: builder.query({
            query: () => '/relay',
        }),
        updateRelayStatus: builder.mutation({
            query: (body) => ({
                url: '/relay/update',
                method: 'POST',
                body,
            }),
        }),
        getChangeBattery: builder.query({
            query: () => 'battery/change',
        }),

        updateBattery: builder.mutation({
            query: (body) => ({
                url: '/battery/update',
                method: 'POST',
                body,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(api.util.invalidateTags(['StorageBattery']));
                } catch (error) {
                    console.error('Помилка під час оновлення батареї:', error);
                }
            },
        }),
        getGeneratorStatus: builder.query({
            query: () => '/generator/status',
            providesTags: ['Generator'], // Прив'язка до тега
        }),
        getGeneratorIP: builder.query({
            query: () => '/generator/ip',
            providesTags: ['Generator'],
        }),
        updateUniversalSwitchChange: builder.mutation({
            query: ({ endpoint, body }) => ({
                url: endpoint,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Generator'], // Інвалідація тега після мутації
        }),
    }),
});

export const {
    useGetElectricityDataQuery,
    useGetElectricityFilterDataQuery,
    useGetRelayDataQuery,
    useUpdateRelayStatusMutation,
    useGetChangeBatteryQuery,
    useGetGeneratorStatusQuery,
    useUpdateUniversalSwitchChangeMutation,
    useGetGeneratorWorkTimeQuery
} = api;
