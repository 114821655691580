import React, { useState } from "react";
import {
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    TextField,
    Button,
    Divider,
    IconButton,
    Switch, TableContainer, Paper, TableHead, TableCell, TableRow, TableBody, Grid2,
} from "@mui/material";
import {
    useGetGeneratorStatusQuery,
    useGetGeneratorWorkTimeQuery,
    useUpdateUniversalSwitchChangeMutation
} from "../../shared/api/api";
import {Table} from "@mui/joy";
import OilBarrelIcon from '@mui/icons-material/OilBarrel';

const Generator = () => {
    const [chokeValue, setChokeValue] = useState(130);

    const [sendSwitchRequest, { isLoading: isSwitchLoading, error: switchError }] =
        useUpdateUniversalSwitchChangeMutation();
    const { data: generatorStatus, isLoading: isLoadingGeneratorStatus } =
        useGetGeneratorStatusQuery();
    const { data: generatorWorkTime, isLoading: isLoadingGeneratorWorkTime } =
        useGetGeneratorWorkTimeQuery();

    const handleSwitchChange = async (endpoint, state) => {
        try {
            await sendSwitchRequest({ endpoint: `/generator/${endpoint}`, body: { state } }).unwrap();
        } catch (err) {
            console.error(`Error updating ${endpoint}:`, err);
        }
    };

    const handleAutoWork = async (endpoint) => {
        try {
            await sendSwitchRequest({ endpoint: `/generator/${endpoint}` }).unwrap();
        } catch (err) {
            console.error(`Error updating ${endpoint}:`, err);
        }
    };

    const handleChokeSubmit = async () => {
        try {
            await sendSwitchRequest({ endpoint: '/generator/test/servo', body: { step: chokeValue } }).unwrap();
        } catch (err) {
            console.error("Error submitting choke:", err);
        }
    };

    const handleChokeInputChange = (event) => {
        const value = Math.min(180, Math.max(30, parseInt(event.target.value, 10) || 60));
        setChokeValue(value);
    };

    const formatValue = (name, value) => {
        if (["last_working_time", "work_time_hors"].includes(name)) {
            return `${parseFloat(value).toFixed(2)} hours`; // Форматування для годин
        }
        if (["date_last_end", "date_change_oil", "date_last_start"].includes(name)) {
            return new Date(value).toLocaleString(); // Форматування для дати й часу
        }
        return value; // Повернення для інших значень
    };


    const GeneratorSwitch = ({ label, endpoint, checked }) => (
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
            <Switch
                checked={checked}
                onChange={(e) => handleSwitchChange(endpoint, e.target.checked)}
                disabled={isSwitchLoading}
                color="primary"
            />
        </Box>
    );

    if (isLoadingGeneratorStatus) {
        return (
            <Card sx={{ maxWidth: 600, margin: "20px auto", padding: 3, boxShadow: 6 }}>
                <CircularProgress sx={{ display: "block", margin: "0 auto" }} />
            </Card>
        );
    }

    return (
        <Card sx={{ maxWidth: 600, margin: "20px auto", padding: 3, boxShadow: 6, borderRadius: 3 }}>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                <Typography variant="h5" sx={{ fontWeight: 700, color: "primary.main" }}>
                    PREDATOR 3500
                </Typography>
                <IconButton
                    onClick={() => handleAutoWork('change-oil')}
                    color="primary">
                    <OilBarrelIcon />
                </IconButton>
            </Grid2>
            <Divider sx={{ mb: 2 }} />

            <CardContent>
                <Box sx={{ mb: 3 }}>
                    {[
                        { label: "Fuel", endpoint: "fuel", checked: generatorStatus?.data?.fuelEnable },
                        { label: "Choke", endpoint: "servo", checked: generatorStatus?.data?.chokeEnable },
                        { label: "Panel", endpoint: "panel", checked: generatorStatus?.data?.panelEnabled },
                        { label: "Starter", endpoint: "starter", checked: generatorStatus?.data?.starterEnabled },
                        { label: "Eco Mode", endpoint: "eco_mode", checked: generatorStatus?.data?.ecoModeEnabled },
                    ].map((switchConfig) => (
                        <GeneratorSwitch
                            key={switchConfig.endpoint}
                            label={switchConfig.label}
                            endpoint={switchConfig.endpoint}
                            checked={switchConfig.checked}
                        />
                    ))}
                </Box>

                <Box sx={{ mb: 3 }}>
                    <Typography>
                        Choke Position: <strong>{generatorStatus?.data?.servoPosition + 2}</strong>
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" gap={1} mb={3}>
                    <Typography sx={{ fontWeight: 500 }}>Handle Choke:</Typography>
                    <TextField
                        type="number"
                        value={chokeValue}
                        onChange={handleChokeInputChange}
                        variant="outlined"
                        size="small"
                        inputProps={{ min: 40, max: 130 }}
                        sx={{ width: 80 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChokeSubmit}
                        size="small"
                        disabled={isSwitchLoading}
                    >
                        SET
                    </Button>
                </Box>

                <Box display="flex" gap={1} mb={3}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleAutoWork('autostart')}
                        size="medium"
                        disabled={isSwitchLoading}
                        sx={{ flex: 1 }}
                    >
                        AutoSTART
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleAutoWork('autostop')}
                        size="medium"
                        disabled={isSwitchLoading}
                        sx={{ flex: 1 }}
                    >
                        AutoSTOP
                    </Button>
                </Box>

                {switchError && (
                    <Typography color="error" mt={2} sx={{ fontWeight: 500 }}>
                        Error: {switchError.data?.message || "Something went wrong"}
                    </Typography>
                )}

                <Box mt={3}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                        Sensors Status:
                    </Typography>
                    {[
                        { label: "🔌 Generator (12V)", value: generatorStatus?.data?.voltageGenerator, unit: "V" },
                        { label: "🔋 Battery", value: generatorStatus?.data?.voltageBattery, unit: "V" },
                        { label: "🌡️ Temperature", value: generatorStatus?.data?.temperature, unit: "°C" },
                        { label: "📳 Vibration", value: generatorStatus?.data?.vibrationDetected ? "Yes" : "No" },
                    ].map((status) => (
                        <Typography key={status.label} sx={{ mb: 1 }}>
                            {status.label}: <strong>{status.value ?? "N/A"} {status.unit}</strong>
                        </Typography>
                    ))}
                </Box>

                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" gutterBottom textAlign="center">
                        Work Time
                    </Typography>
                    <TableContainer component={Paper} sx={{ maxWidth: 800, margin: "0 auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Setting Name</strong></TableCell>
                                    <TableCell><strong>Setting Value</strong></TableCell>
                                    <TableCell><strong>Last Updated</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {generatorWorkTime?.data && generatorWorkTime.data.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.setting_name}</TableCell>
                                        <TableCell>{formatValue(item.setting_name, item.setting_value)}</TableCell>
                                        <TableCell>{new Date(item.updated_at).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Generator;
